<template>
    <div class="bar">
        <div>
            <div class="text1">
                <slot>XXX系统平台</slot>
            </div>
            <div class="text2">
                <span> <slot name="sub"></slot></span>
                <span class="prim"> <slot name="sub-prim"></slot></span>
            </div>
        </div>
    </div>
</template>

<script>
export default {};
</script>

<style lang="less" scoped>
.bar {
    padding: 10px 16px;
    background-color: @bg;
    .flex();

    .text1 {
        font-size: 20px;
        font-weight: bold;
    }

    .text2 {
        font-size: 12px;
        color: #939599;
        line-height: 22px;
        margin-top: 4px;
        .prim {
            color: @prim;
        }
    }
}
</style>
