<template>
    <div class="page-title">
        <div class="navigation">
            <div class="title">{{ $route.meta.title || $route.name }}</div>
            <el-breadcrumb separator="/">
                <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
                <el-breadcrumb-item>{{ $route.meta.title || $route.name }}</el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <div class="btns">
            <slot></slot>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {};
    }
};
</script>
<style lang="less" scoped>
.page-title {
    height: 70px;
    min-height: 70px;
    background: white;
    &:before {
        content: '';
        display: inline-block;
        height: 100%;
        vertical-align: middle;
    }
    > * {
        display: inline-block;
        vertical-align: middle;
    }
    .navigation {
        min-width: 300px;
        margin-left: 20px;
        .title {
            color: black;
            font-weight: bold;
            font-size: 16px;
            line-height: 24px;
            margin-bottom: 8px;
        }
        .el-breadcrumb {
            font-size: 13px;
        }
    }
    .btns {
        vertical-align: middle;
        float: right;
        height: 70px;
        margin: 0 20px;
        &:before {
            content: '';
            display: inline-block;
            height: 100%;
            vertical-align: middle;
        }
        > * {
            vertical-align: middle;
            display: inline-block;
        }
    }
}
</style>
