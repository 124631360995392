import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        tableHeight: 0,
        fetchingData: false,
        userInfo: null,
    },
    mutations: {
        updateTableHeight(state, height) {
            state.tableHeight = height;
        },
        updateFetchingData(state, fetchingData) {
            state.fetchingData = fetchingData;
        },
        updateUserInfo(state, userInfo) {
            state.userInfo = userInfo;
        },
    },
    actions: {}
});
