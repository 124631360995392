<template>
    <el-date-picker
        v-model="date"
        :type="dateType"
        :picker-options="pickerOptions"
        range-separator="至"
        :start-placeholder="`${name}${startTitle}`"
        :end-placeholder="`${name}${endTitlte}`"
        align="right"
        :value-format="valueFormat"
        :default-time="['00:00:00', '23:59:59']"
        class="filter-item"
        @change="changeSelect"
    >
    </el-date-picker>
</template>

<script>
export default {
    name:"DateRangePicker",
    props: [
        'value', 
        'name',
        'type'
    ],
    created() {
        if (this.value) {
            this.date = this.value;
        }
        
        this.dateType = this.type;
        if(this.dateType == 'date' || this.dateType == 'daterange'){
            this.valueFormat = "yyyy-MM-dd";
            this.startTitle = "开始日期";
            this.endTitlte = "结束日期";
        }else if(this.dateType == 'datetime' || this.dateType == 'datetimerange'){
            this.valueFormat = "yyyy-MM-dd HH:mm:ss";
            this.startTitle = "开始时间";
            this.endTitlte = "结束时间";
        }else{
            this.dateType = "datetimerange";
            this.valueFormat = "yyyy-MM-dd HH:mm:ss";
            this.startTitle = "开始时间";
            this.endTitlte = "结束时间";
        }

    },
    data() {
        return {
            dateType:"",
            valueFormat: "",
            startTitlte: "",
            endTitlte:"",
            date: '',
            pickerOptions: {
                shortcuts: [
                    {
                        text: '最近一周',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                            picker.$emit('pick', [start, end]);
                        }
                    },
                    {
                        text: '最近一个月',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
                            picker.$emit('pick', [start, end]);
                        }
                    },
                    {
                        text: '最近三个月',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
                            picker.$emit('pick', [start, end]);
                        }
                    }
                ]
            }
        };
    },

    methods: {
        changeSelect(val) {
            this.$emit('input', val);
        }
    },
    watch: {
        value() {
            if (this.value) {
                this.date = this.value;
            }
        }
    }
};
</script>

<style lang="less" scoped></style>
