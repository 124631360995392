<template>
    <div class="json-container">
        <div class="json-tool">
            <el-button type="primary" class="json-btn" @click="viewOrigin">切换视图</el-button>
        </div>
        
        
        <div class="error-view" v-if="errorInfo">
            {{ errorInfo }}
        </div>
        

        <div class="json-view"    v-if="parseVisible"  >
            <el-tree :data="treeData" 
            :render-content="renderContent" 
            :expand-on-click-node="true"
            
            >

            </el-tree>
        </div>

        <div class="defualt-view"  v-else >
           <textarea class="default-text">
            {{ jsonText }}
           </textarea>
        </div>

        
    </div>
</template>
<script>

let idSn = 1000;

export default {
    name: 'jsonView',
    created() {
        this.parseTreeData();
    },
    props: {
        json: {
            required: true
        },
        rootKey: {
            type: String,
            default: 'root'
        }
    },
    data() {
        return {
            treeData: [],
            parseVisible: false,
            errorInfo:null,
        };
    },
    methods: {
        parse(originValue){

            let obj = null;
            if(!(originValue instanceof Object) && !(originValue instanceof Array)){
                obj = JSON.parse(originValue);
            }else{
                obj  = originValue;;
            }

            let root = [];
            if (obj instanceof Object) {
                for (let key in obj) {
                    if (obj[key] instanceof Array) {
                        root.push({
                            id: ++idSn,
                            label: key + ': Array[' + obj[key].length + ']',
                            children: this.parse(obj[key])
                        });
                    } else if (obj[key] instanceof Object) {
                        root.push({
                            id: ++idSn,
                            label: key + ': Object',
                            children: this.parse(obj[key])
                        });
                    } else {
                        root.push({
                            id: ++idSn,
                            label: key + ': ' + obj[key]
                        });
                    }
                }
            } else if (obj instanceof Array) {
                for (let i = 0; i < obj.length; i++) {
                    if (obj[i] instanceof Array) {
                        root.push({
                            id: ++idSn,
                            label: i + ': Array[' + obj[i].length + ']',
                            children: this.parse(obj[i])
                        });
                    } else if (obj[i] instanceof Object) {
                        root.push({
                            id: ++idSn,
                            label: i + ': Object',
                            children: this.parse(obj[i])
                        });
                    } else {
                        root.push({
                            id: ++idSn,
                            label: i + ': ' + obj[i]
                        });
                    }
                }
            }


            return root;
        },

        parseTreeData() {
            try{
                this.errorInfo = null;
                this.treeData = this.parse(this.json);
                this.parseVisible = true;
            }catch(e){
                console.error("解析失败:" + e.message);
                this.parseVisible = false;
                this.errorInfo = e.message;
            }
           
        },
        renderContent(h, { node, data, store }) {
            return h(
                'span',
                {
                    class: 'json-view-content'
                },
                [
                    h('span', { class: 'json-view-content-key' }, [data.label.split(':')[0]]),
                    ':',
                    data.label.split(':')[1]
                ]
            );
        },
        viewOrigin(){
            this.parseVisible = !this.parseVisible;
        }
    },
    computed:{
        jsonText(){
            try{
                let obj  = null;
                if(typeof this.json == "string"){
                    obj = JSON.parse(this.json);
                }else{
                    obj = this.json;
                }

                let str = JSON.stringify(obj, null, 8);
                return str;
            }catch(e){
                console.error("json error", e.message);
            }

            return this.json;
        }
    },
    watch: {
        json() {
            this.parseTreeData();
        }
    }
};
</script>
<style lang="less" scoped>

.json-container{
    position: relative;
    padding-top: 30px;

}

.json-tool{
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    justify-content: flex-end;
}


.json-btn{
    margin:0 20px;
}

.json-view{
    height: 450px;
    overflow: auto;
}

/deep/ .json-view .json-view-content {
    cursor: text !important;
    font-family: 'dejavu sans mono', 'droid sans mono', consolas, monaco, 'lucida console', 'courier new', courier,
        monospace, sans-serif;
    font-size: 13px !important;
}

/deep/ .json-view .json-view-content .json-view-content-key {
    color: @prim;
}

.defualt-view{
    padding: 30px;

}

.default-text{
    width: 100%;
    height: 450px;
}

.error-view{
    padding: 30px;
    border:solid 1px red;
    color: #cc0000;
}




</style>
