import axios from 'axios';
import router from '../router';
import qs from 'qs';
/* eslint-disable */
let baseUrl = process.env.VUE_APP_BASE_URL;
const axiosInstance = axios.create({
    baseURL: baseUrl
});

axiosInstance.interceptors.request.use(
    function(config) {
        config.headers = config.headers || {};
        let token = localStorage.getItem('token');
        if (token) {
            config.headers['Authorization'] = 'Bearer ' + token;
        }
        return config;
    },
    function(error) {
        if(!error.error){
            error.error = error.message;
        }
         
        return Promise.reject(error);
    }
);

axiosInstance.interceptors.response.use(
    function(response) {
        return response;
    },
    function(error) {
        let errorData = {};
        if (!error.response) {
            errorData = {
                error: '网络错误，请检查网络链接'
            };
        } else {
            errorData = error.response.data;
            if (401 === error.response.status) {
                if (router.currentRoute.name !== 'login') {
                    router.replace({
                        name: 'login',
                        params: {
                            from: router.currentRoute.name
                        }
                    });
                } else {
                }
            }
        }
        
        if(window.Blob && errorData instanceof window.Blob){
            //导出错误转换
            try{
                return errorData.text().then((data) => {
                    let errorObj = JSON.parse(data);
                    return Promise.reject(errorObj);
                });
            }catch(e){
                //ignore
            }
        }

        if (typeof errorData != 'object') {
            errorData = {
                error: '请求失败' + error.response.status
            };
        }

        if(errorData && !errorData.error){
            errorData.error = "未知错误";
        }

        return Promise.reject(errorData);
    }
);
export default {
    axios: axiosInstance,
    install(_Vue, options) {
        _Vue.prototype.$baseUrl = baseUrl;
        _Vue.prototype.$axios = axiosInstance;
        _Vue.prototype.$http = {
            get(url, params) {
                params = params || {};
                return new Promise((resolve, reject) => {
                    axiosInstance
                        .get(
                            url,
                            {
                                params: params
                            },
                            { withCredentials: true }
                        )
                        .then(res => {
                            resolve(res.data);
                        })
                        .catch(e => {
                            reject(e);
                        });
                });
            },
            post(url, body, options) {
                options = options || {};
                body = body || {};
                if (!(body instanceof FormData)) {
                    if (options.body !== 'json') {
                        body = qs.stringify(body);
                    }
                }
                return new Promise((resolve, reject) => {
                    axiosInstance
                        .post(url, body, { withCredentials: true })
                        .then(res => {
                            resolve(res.data);
                        })
                        .catch(e => {
                            reject(e);
                        });
                });
            }
        };
    }
};
