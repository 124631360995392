<template>
    <el-select
        v-model="userId"
        filterable
        remote
        :remote-method="remoteMethod"
        :loading="loading"
        clearable=""
        :disabled="disabled"
        :multiple="isMultiple ? true : false"
        placeholder="请输入用户名搜索"
    >
        <el-option v-for="item in filterOptions" :label="item.username" :value="item.id" :key="item.id">
            <span style="float: left">{{ item.username }}</span>
            <span style="float: right; color: #8492a6; font-size: 13px"> #{{ item.id }} </span>
        </el-option>
    </el-select>
</template>
<script>
export default {
    props: ['value', 'isMultiple', 'disabled'],
    created() {
        if (this.value != null) {
            this.userId = this.value;
            this.remoteMethod(this.userId);
        }
    },
    data() {
        return {
            options: [],
            filterOptions: [],
            userId: null,
            selected: null,
            loading: false
        };
    },
    methods: {
        remoteMethod(query) {
            this.loading = true;
            this.$http
                .post(
                    '/user/all',
                    {
                        size: 20,
                        sort: 'createdAt,desc',
                        query: { del: false },
                        search: query
                    },
                    { body: 'json' }
                )
                .then(res => {
                    this.filterOptions = res.content;
                })
                .finally(() => {
                    this.loading = false;
                });
        }
    },
    watch: {
        userId(val) {
            this.$emit('input', val);
            if (val) {
                this.selected = this.options.find(i => i.id === val);
                this.$emit('select', this.selected);
            }
        },
        value(val) {
            this.userId = val;
            //触发搜索
            this.remoteMethod(this.userId);
        }
    }
};
</script>
