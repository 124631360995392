import Vue from 'vue';
import Router from 'vue-router';
import Admin from '@/views/Admin';
import store from './store';
import http from './plugins/http';

Vue.use(Router);

const router = new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [
        {
            path: '/',
            component: Admin,
            children: [
                {
                    path: '/',
                    redirect: 'dashboard'
                },
                {
                    path: '/404',
                    name: '404',
                    component: () => import(/* webpackChunkName: "404" */ '@/views/404.vue')
                },
                {
                    path: '/dashboard',
                    name: 'dashboard',
                    component: () => import(/* webpackChunkName: "404" */ '@/views/Dashboard.vue'),
                    meta: {
                        title: '首页'
                    }
                },
                {
                    path: '/api',
                    name: 'api',
                    component: () => import(/* webpackChunkName: "api" */ '@/views/Api.vue'),
                    meta: {
                        title: '接口调试'
                    }
                },
                {
                    path: '/genCodeEdit',
                    name: 'genCodeEdit',
                    component: () => import(/* webpackChunkName: "genCodeEdit" */ '@/views/GenCodeEdit.vue'),
                    meta: {
                        title: '代码生成编辑'
                    }
                },
                {
                    path: '/genCodeList',
                    name: 'genCodeList',
                    component: () => import(/* webpackChunkName: "genCodeList" */ '@/views/GenCodeList.vue'),
                    meta: {
                        title: '代码生成'
                    }
                },
                {
                    path: '/menus',
                    name: 'menus',
                    component: () => import(/* webpackChunkName: "menus" */ '@/views/Menus.vue'),
                    meta: {
                        title: '菜单配置'
                    }
                },
                {
                    path: '/menuAuthority',
                    name: 'menuAuthority',
                    component: () => import(/* webpackChunkName: "menuAuthority" */ '@/views/MenuAuthority.vue'),
                    meta: {
                        title: '菜单权限'
                    }
                },
                {
                    path: '/userEdit',
                    name: 'userEdit',
                    component: () => import(/* webpackChunkName: "userEdit" */ '@/views/UserEdit.vue'),
                    meta: {
                        title: '用户编辑'
                    }
                },
                {
                    path: '/userList',
                    name: 'userList',
                    component: () => import(/* webpackChunkName: "userList" */ '@/views/UserList.vue'),
                    meta: {
                        title: '用户管理'
                    }
                },
                {
                    path: '/sysConfigList',
                    name: 'sysConfigList',
                    component: () => import(/* webpackChunkName: "sysConfigList" */ '@/views/SysConfigList.vue'),
                    meta: {
                        title: '参数配置'
                    }
                },
               
                {
                    path: '/adminEdit',
                    name: 'adminEdit',
                    component: () => import(/* webpackChunkName: "adminEdit" */ '@/views/AdminEdit.vue'),
                    meta: {
                        title: '账号编辑'
                    }
                },
                {
                    path: '/adminList',
                    name: 'AdminList',
                    component: () => import(/* webpackChunkName: "adminList" */ '@/views/AdminList.vue'),
                    meta: {
                        title: '账号管理'
                    }
                },
                {
                    path: '/gameEmailList',
                    name: 'GameEmailList',
                    component: () => import(/* webpackChunkName: "adminList" */ '@/views/GameEmailList.vue'),
                    meta: {
                        title: '邮件管理'
                    }
                },
                {
                    path: '/orderList',
                    name: 'OrderList',
                    component: () => import(/* webpackChunkName: "adminList" */ '@/views/OrderList.vue'),
                    meta: {
                        title: '订单管理'
                    }
                },
                {
                    path: '/signArriveList',
                    name: 'SignArriveList',
                    component: () => import(/* webpackChunkName: "adminList" */ '@/views/SignArriveList.vue'),
                    meta: {
                        title: '签到数据'
                    }
                },
                {
                    path: '/gameDataList',
                    name: 'GameDataList',
                    component: () => import(/* webpackChunkName: "adminList" */ '@/views/GameDataList.vue'),
                    meta: {
                        title: '游戏数据'
                    }
                },
                {
                    path: '/errLogList',
                    name: 'ErrLogList',
                    component: () => import(/* webpackChunkName: "adminList" */ '@/views/ErrLogList.vue'),
                    meta: {
                        title: '埋点日志'
                    }
                },
                {
                    path: '/userFeedbackList',
                    name: 'UserFeedbackList',
                    component: () => import(/* webpackChunkName: "adminList" */ '@/views/UserFeedbackList.vue'),
                    meta: {
                        title: '反馈日志'
                    }
                },
                /**INSERT_LOCATION**/
            ]
        },
        {
            path: '/login',
            name: 'login',
            component: () => import('@/views/Login'),
            meta: {
                title: '登录'
            }
        },
        {
            path: '*',
            name: 'notFound',
            component: () => import('@/views/404.vue')
        }
    ]
});

//无需登录的页面
const notLoginPath = [
    "/login",
    "/photoProcessing",
    "/openSecondList",
    "/404",
];

router.beforeEach((to, from, next) => {
    //console.log(to);
    if (/^\/http/.test(to.path)) {
        console.log(Vue.$baseUrl);
        let url = to.path.replace('/', '');
        let params = [];
        if (to.query) {
            for (let key in to.query) {
                if (to.query.hasOwnProperty(key)) {
                    params.push(`${key}=${to.query[key]}`);
                }
            }
        }
        if (params.length > 0) {
            url += `?${params.join('&')}`;
        }
        window.open(url);
        return;
    }
    
    if (!store.state.userInfo && notLoginPath.indexOf(to.path)  == -1) {
        http.axios
            .get('/user/my')
            .then(res => {
                store.commit('updateUserInfo', res.data);
                next();
                return;
            })
            .catch(() => {
                localStorage.removeItem('token');
                next('/login');
            });
    } else if (!to.matched.length) {
        next('/404');
    } else {
        next();
    }
});

export default router;
