module.exports = {
  "divider": "#f2f4f5",
  "prim": "#409eff",
  "success": "#67c23a",
  "danger": "#f56c6c",
  "warn": "#e6a23c",
  "text0": "#181818",
  "text1": "#323233",
  "text2": "#606266",
  "text3": "#939599",
  "text4": "#c6c8cc",
  "border1": "#dcdfe6",
  "border2": "#f2f3f5",
  "border3": "#dfe1e6",
  "border4": "#f2f6fc",
  "bg": "#f2f4f5",
  "menu-text-color": "#bfcbd9",
  "menu-text-color-active": "#409eff",
  "menu-bg": "#324157",
  "menu-bg-active": "#1f2d3d",
  "menu-bg-hover": "#232e3b"
};
