<template>
    <el-dialog :title="title"  :visible.sync="visible"  :width="width" :close-on-click-modal="false">
        <el-form ref="editForm" :model="editForm" :rules="rules"  :label-width="labelWidth" v-loading="loading" >
            <slot :editForm="editForm" >
                <el-form-item v-for="item in formItems"  :label="item.label" :prop="item.prop">
                    <!-- 自定义输入框组件 -->
                    <template v-if="item.inputComponent != null">
                        <component :is="item.inputComponent" v-model="editForm[item.prop]" :disabled="item.disabled"  ></component>
                    </template>
                    <template v-else-if="item.optionData != null">
                        <el-checkbox-group  v-model="editForm[item.prop]" :disabled="item.disabled"  v-if="item.type == 'checkbox'">
                            <el-checkbox v-for="option in optionData" :label="option.value">
                                {{ option.name }}
                            </el-checkbox>
                        </el-checkbox-group>
                        <el-radio-group  v-model="editForm[item.prop]" :disabled="item.disabled"  v-if="item.type == 'radio'">
                            <el-radio v-for="option in optionData" :label="option.value">
                                {{ option.name }}
                            </el-radio>
                        </el-radio-group>
                        <el-select  v-model="editForm[item.prop]" :disabled="item.disabled"  v-if="item.optionData">
                            <el-option :value="item.value" :label="item.name">
                                {{ item.name }}
                            </el-option>
                        </el-select>
                    </template>
                    <template v-else-if="dateTypes.includes(item.type)">
                        <el-date-picker
                        v-model="editForm[item.prop]"
                        :disabled="item.disabled" 
                        :type="item.type"
                        :value-format="item.valueFormat || dateFormatMap[item.type]"
                        :placeholder="'请选择' + item.label">
                        </el-date-picker>
                    </template>
                    
                    <el-input :type="item.type" 
                    v-model="editForm[item.prop]" 
                    :disabled="item.disabled" 
                    :placeholder="'请输入' + item.label"
                    :rows="item.rows"
                    :min="item.min"
                    :max="item.max"
                    v-else>
                    </el-input> 
                </el-form-item>
            </slot>
        </el-form>
        
        <div slot="footer">
            <el-button type="primary" @click="onSubmit" :disabled="loading" >提交</el-button>
            <el-button @click="visible = false" >取消</el-button>
        </div>

    </el-dialog>
</template>


<script>

export default {
    props: {
        width:{
            type:String,
            default: "580px"
        },
        labelWidth:{
            type: String,
            default:"120px"
        },
        url:{
            type: String,
            default:""
        },
        formItems: {
            type: Array,
            default(){
                //格式 {label:xxx, prop:xxx, type: xxx, value:xxx, optionData:[{name:xxx,value:xxx}]}
                return [];
            }
        },
        preventDefault: {
            type: Boolean,
            default: false
        }
    },

    data() {
        return {
           title:"",
           visible: false,
           loading: false,
           editForm:{

           },
           rules: {},
           //可用有很多：year/month/date/dates/months/years week/datetime/datetimerange/ daterange/monthrange
           dateTypes:[
            "date",
            "datetime",
            "daterange",
            "datetimerange"
           ],
           dateFormatMap:{
            "date":"yyyy-MM-dd",
            "datetime":"yyyy-MM-dd HH:mm:ss",
            "daterange":"yyyy-MM-dd",
            "datetimerange":"yyyy-MM-dd HH:mm:ss",
           }
        };
    },
    mounted() {
        
        //设置验证规则,参考：rules:[{required: true, message:"不能为空"}]
        if(this.formItems){
            this.formItems.forEach(item => {
                let ary = [];
                if(item.required){
                    ary.push({required: true, message:"不能为空", trigger:"blur"});
                }

                if(item.rules){
                    ary = ary.concat(item.rules);
                }

                //添加规则
                this.rules[item.prop] = ary;
            })
        }
        

    },
    computed:{
       
    },

    methods: {
        init(row) {
            //init 方法必须提供
            this.visible  = true;
            let initObj = {};
            if(this.formItems){
                this.formItems.forEach(item => {
                    //默认值
                    initObj[item.prop] = item.value ;
                })
            }

            if(row){
                this.editForm = {...initObj, ...row};
            }else{
                this.editForm = initObj;
            }

            if(this.editForm.id){
                this.title = "编辑["+this.editForm.id+"]";
            }else{
                this.title = "新增";
            }
            
            this.$nextTick(() => {
                this.$refs.editForm.clearValidate();
            });
            
        },
        onSubmit(){

            this.$refs.editForm.validate(async (valid) => {
                if(valid){
                     //阻止默认操作
                     if(this.preventDefault){
                        return;
                    }

                    //默认提交数据
                    this.loading = true;
                    try{
                        let res = await this.$http.post(this.url, this.editForm, {body:"json"});
                        this.$message.success("操作成功");
                        this.visible = false;
                        //刷新
                        this.$emit("refresh");
                    }catch(e){
                        this.$message.error(e.error);
                    }finally{
                        this.loading = false;
                    }
                }
            });

           
        }
    },
    watch: {
        
    }
};


</script>


<style lang="less" scoped>


</style>