import Vue from 'vue';
import App from './App';
import router from './router';
import store from './store';
import ElementUI from 'element-ui';
import http from './plugins/http';
import dataExport from '@/plugins/dataExport';
import Formatters from '@/mixins/formatters';
import 'normalize.css/normalize.css';
import './styles/element_theme/index.css';
import theme from '!less-vars-loader!./styles/common/theme.less';

// import VueAMap from "vue-amap";
// Vue.use(VueAMap);
// VueAMap.initAMapApiLoader({
//     key: 'bf91055058a47a7dc387e40ab6256a5f',
//     plugin: [
//         'Autocomplete',
//         'PlaceSearch',
//         'Scale',
//         'OverView',
//         'ToolBar',
//         'MapType',
//         'PolyEditor',
//         'AMap.CircleEditor',
//         'AMap.Geolocation',
//         'AMap.DistrictSearch',
//         'AMap.Geocoder'
//     ],
//     uiVersion: '1.1',
//     v: '1.4.15'
// });

Vue.config.productionTip = false;
Vue.use(ElementUI, { size: 'small' });
Vue.use(http);
Vue.use(dataExport);

//注册公共组件
const moduleFiles = require.context("@/components/common", true, /\.vue$/);
moduleFiles.keys().forEach(item => {
    let name = item.replace(/.*\/(.+)\.vue/, "$1");
    let value = moduleFiles(item).default;
    Vue.component(name, value);
})


Vue.mixin(Formatters);
Vue.prototype.$theme = theme;
console.log("当前主题：", theme);
new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app');
