<template>
    <div class="page-container"  >

        <slot name="filter"  
            v-bind="$data"
            :searchData="searchData"
            :download="download"
        >
            <div class="filters-container" @keyup.enter="searchData">
                <!-- 指定条件 -->
                <slot name="condition">
                    <el-input placeholder="输入关键字" v-model="innerSearch" clearable class="filter-item"></el-input>
                </slot>
                
                <el-button @click="searchData" 
                type="primary" 
                icon="el-icon-search"
                class="filter-item" 
                :disabled="fetchLoading"
                title="支持按下enter键搜索"
                >
                    搜索 
                </el-button>
                <el-button
                    v-if="downloadUrl"
                    @click="download(downloadUrl)"
                    type="primary"
                    icon="el-icon-download"
                    :loading="downLoading"
                    class="filter-item"
                    >
                    导出EXCEL
                </el-button>
            </div>
        </slot>

        <slot 
            v-bind="$data"
            :searchData="searchData"
            :download="download"
            :onSortChange="onSortChange"
        >
            
            <el-table
                :data="content"
                row-key="id"
                ref="table"
                :height="tableHeight"
                v-loading="fetchLoading"
                @sort-change="onSortChange"
                @selection-change="onSelectionChange"
            >
                <!-- 提供列的定义即可 -->
                <slot name="table" :table="$refs.table">
                    <el-table-column  v-for="(value, key) in firstColumnData" :key="key" :prop="key" :label="key"> </el-table-column>
                </slot>
                <el-table-column fixed="right" label="操作" min-width="180px"  v-if="opAble"  >
                    <template v-slot="{row}">
                        <el-button class="op-btn" icon="el-icon-edit" type="text" @click="onEdit(row)" v-if="editRef">编辑</el-button>
                        <el-button class="op-btn" icon="el-icon-delete" type="text" @click="onDelete(row)" v-if="deleteUrl">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>

        </slot>

        <div class="pagination-wrapper">
            <el-pagination 
            background 
            @size-change="onSizeChange" 
            @current-change="onCurrentChange" 
            :current-page="pageQuery.page"
            :page-sizes="[10, 20, 30, 40, 50]" 
            :page-size="pageQuery.size" 
            layout="total, sizes, prev, pager, next, jumper"
            :total="totalElements">
            </el-pagination>
        </div>

    </div>
</template>


<script>

export default {
    props: {
        url: String,
        downloadUrl: String,
        deleteUrl: String,
        editRef: String,
        query: {
            type: Object,
            default() {
                return {};
            }
        },
        sort: String,
        search: String,
    },

    data() {
        return {
            pageQuery: {
                page: 1,
                size: Number(localStorage.getItem('pageSize') || 20),
                sort: 'createdAt,desc', //例如 'createdAt,desc' 是以时间排序,倒序
                search: '',
                query: {
                    del: false
                },
            },
            sortObj: {},
            totalPages: 0,
            totalElements: 0,
            content: [],
            tableHeight: 200,
            fetchLoading: false,
            downLoading: false,
            innerSearch: "",
            selectionData:[],
        };
    },
    created() {
        this.pageQuery.page = Number(this.$route.query.page) || 1;
        if (this.$route.query.sort) {
            this.initSortObj(this.$route.query.sort);
            this.$emit("update:sort", this.getSortStr());
        } else {
            if (this.sort) {
                this.pageQuery.sort = this.sort;
            }
        }

        if (this.$route.query) {
            let query = {
                ...this.$route.query
            };
            delete query.sort;
            delete query.page;
            delete query.size;
            this.pageQuery.query = {
                ...this.pageQuery.query,
                ...query
            };
            this.$emit("update:query", this.pageQuery.query);
        }

        this.innerSearch = this.search;

    },
    mounted() {
        
        if (this.sort) {
            this.initSortObj(this.sort);
        }
        
        let elTable = document.querySelector(".el-table");
        if(elTable){
            this.tableHeight = elTable.getBoundingClientRect().height;
        }
        
        if(this.url){
            //自动搜索
            this.searchData();
        }

    },
    computed:{
        firstColumnData(){
            if(this.content && this.content.length > 0){
                let res = { ...this.content[0]};
                delete res.del;
                return res;
            }

            return {};
        },
        opAble(){
            if(this.deleteUrl){
                return true;
            }
            return false;
        }
    },

    methods: {
        initSortObj(sortStr) {
            let sortObj = {};
            if (sortStr) {
                let sortAry = sortStr.split(";");
                sortAry.forEach(item => {
                    let strAry = item.split(",");
                    sortObj[strAry[0]] = strAry[1];
                })
            }
            this.sortObj = sortObj;
        },
        getSortStr() {
            let sortAry = [];
            for (let key in this.sortObj) {
                let str = key ;
                if(this.sortObj[key]){
                    str += "," + this.sortObj[key];
                }
                sortAry.push(str);
            }
            return sortAry.join(";");
        },
        //加载数据
        loadData(init) {
            if (init) {
                this.pageQuery.page = 1;
            }
            //使用外部提供的参数
            this.pageQuery.query = {
                ...this.pageQuery.query,
                ...this.query
            }

            let sortStr = this.getSortStr();
            if (sortStr) {
                this.pageQuery.sort = sortStr;
            }

            this.pageQuery.search = this.innerSearch;

            let requestData = {
                ...this.pageQuery
            };

            //前置处理
            this.$emit("beforeLoad", requestData);

            //页码减去1
            requestData.page -= 1;


            this.fetchLoading = true;
            this.$http
                .post(this.url, requestData, {
                    body: 'json'
                })
                .then(res => {
                    this.fetchLoading = false;
                    this.content = res.content;
                    this.totalPages = res.totalPages;
                    this.totalElements = Number(res.totalElements);

                    //后续处理
                    this.$emit("afterLoad", res);

                })
                .catch(e => {
                    this.fetchLoading = false;
                    this.$message.error(e.error);
                });
        },
        searchData() {
            this.loadData(true);
        },
        download(url, filename) {
            if (!url) {
                this.$message.error("没有指定导出url，导出失败");
                return false;
            }
            
            let requestData = {
                ...this.pageQuery
            };

            //前置处理
            this.$emit("beforeLoad", requestData);
            
            //页码减去1
            requestData.page -= 1;

            requestData.size = 10000;
            this.downLoading = true;
            this.$axios.post(url, requestData, {
                    responseType: 'blob'
                }).then(res => {
                    this.downLoading = false;
                    const downloadUrl = window.URL.createObjectURL(new Blob([res.data]));
                    const link = document.createElement('a');
                    link.href = downloadUrl;
                    if (!filename) {
                        filename = decodeURIComponent(res.headers['content-disposition'].split('filename=')[1]);
                    }
                    link.setAttribute('download', filename);
                    document.body.appendChild(link);
                    link.click();
                    link.remove();
                })
                .catch(e => {
                    console.log("download error", e);
                    this.downLoading = false;
                    let errorMsg = e.error || e.message;
                    this.$message.error("导出失败：" + errorMsg);
                });
        },
        changeSort(prop, order) {
            //手动执行排序
            let newOrder = '';
            if (order) {
                if(order == "ascending"){
                    order = "asc";
                }
                if(order == "descending"){
                    order=  "desc";
                }

                if (this.sortObj[prop] !== order) {
                    newOrder = order;
                } else {
                    newOrder = '';
                }
            } else {
                if (this.sortObj[prop] === 'asc') {
                    newOrder = 'desc';
                } else if (this.sortObj[prop] === 'desc') {
                    newOrder = '';
                } else {
                    newOrder = 'asc';
                }
            }
            this.sortObj = {
                ...this.sortObj,
                [prop]: newOrder
            };

            this.$emit("update:sort", this.getSortStr());
        },
        onSortChange({ column, prop, order }) {
            //监听排序变化，执行排序
            this.changeSort(prop, order);
            this.loadData();
        },
        onSelectionChange(val){
            //设置选中的值
            this.selectionData = val;
        },
        onSizeChange(e) {
            localStorage.setItem('pageSize', e);
            this.pageQuery.page = 1;
            this.pageQuery.size = e;
            this.loadData();
        },
        onCurrentChange(e) {
            this.$router
                .replace({
                    query: {
                        ...this.$router.query,
                        page: e
                    }
                })
                .catch(_ => { });
            this.pageQuery.page = e;
            this.loadData();
        },
        async onDelete(row){
            try{
                await this.$confirm("是否删除？", "确认提示", {type:"warning"});
                let loading = this.$loading({text:"正在处理中..."});
                try{
                    let res = await this.$http.post(this.deleteUrl,  {id: row.id});
                    this.$message.success("删除成功");
                    this.loadData();
                }catch(e){
                    this.$message.error(e.error);
                }finally{
                    loading.close();
                }
            }catch(e){
                //ignore
            }

        },
        onEdit(row){
            //对应编辑组件的init方法,监听刷新事件
            let refObj  = this.$parent.$refs[this.editRef];
            if(!refObj){
                this.$message.error("编辑对象未找到：" + this.editRef);
                return;
            }
            refObj.$off("refresh");
            refObj.$on("refresh", () =>{
                this.loadData();
            })
            
            refObj.init(row);
        }

    },
    watch: {
        sort() {
            this.initSortObj(this.sort);
            this.$router.replace({
                query: {
                    ...this.$route.query,
                    sort: this.sort
                }
            });

        },
        search(value, oldValue){
            this.innerSearch = value;
        },
        innerSearch(value, oldValue){
            this.$emit("update:search", value);
        }
    }
};


</script>


<style lang="less" scoped>
.page-container {
    min-height: 200px;
    flex: 1;
    display: flex;
    flex-direction: column;
}

.default-content{
    padding:30px;
    background-color: white;
}

.op-btn{
    margin: 0 8px;
}
</style>